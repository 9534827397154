import React, { Component } from 'react'
import { Link } from 'gatsby'
import Video from 'react-player'

// Components Globals
import Header from 'components/header'
import Navigation from 'components/navigation-solutions'
import Footer from 'components/footer/main'
import Demo from 'components/s-demo/main'
import SEO from 'components/seo'

import {
  BannerSolucao,
  TituloPrimario,
  TextParagrafo,
  SectionInformacoesGerais,
  FerramentasFacilitadoras,
  SectionVideo,
} from '../../Style/solucoes'

import { Container, TitleBanner } from '../../Style/global'

import IlustracaoSolucoesComplementares from 'images/ilustracao-solucoes-complementares.svg'
import IlustracaoIntegracaoFarmacia from 'images/ilustracao-integracao-farmacias.svg'
import IlustracaoBackup from 'images/ilustracao-backup-automatico.svg'
import IlustracaoSegurancaAcesso from 'images/ilustracao-seguranca-acesso.svg'
import IconeSNGPC from 'images/icone-sngpc.svg'
import IconeAuditoriaEstoque from 'images/icone-auditoria-estoque.svg'
import IconeBalancoEstoque from 'images/icone-balanco-estoque.svg'
import IconeContasPagar from 'images/icone-conta-pagar.svg'
import IconeTEF from 'images/icone-tef.svg'
import IconePreco from 'images/icone-etiqueta-preco.svg'
import IconeGerenciadorEntrega from 'images/icone-gerenciador-entregas.svg'
import IconeCofreGerencial from 'images/icone-cofre-gerencial.svg'

// Sass Global
import 'sass/main.scss'

class PageSolucoes extends Component {
  render() {
    return (
      <div className="main">
        <SEO title="Soluções Complementares" />

        <Header />

        <Navigation from={this.props.location.pathname} />

        <BannerSolucao className="banner-solucoes-complementares">
          <Container className="flex">
            <div className="texto">
              <TitleBanner>Soluções complementares</TitleBanner>
              <h1>
                Torne sua experiência
                <br /> ainda mais completa
              </h1>
              <p>
                Centralize processos, automatize fluxos de trabalho e proteja as
                <br /> informações da farmácia de forma fácil, simples e rápida.
              </p>
              <Link to="/sobre/contato">Solicite uma demonstração</Link>
            </div>
            <img
              src={IlustracaoSolucoesComplementares}
              alt="Tela sistema Inovafarma lançar pedidos e giro de estoque"
              className="ilustra"
            />
          </Container>
        </BannerSolucao>

        <SectionInformacoesGerais>
          <Container>
            <div className="item-texto">
              <img src={IlustracaoIntegracaoFarmacia} alt="Inovafarma integrações entre farmácias" className="ilustra" />
              <div className="texto">
                <TituloPrimario>Integrações entre farmácias</TituloPrimario>
                <p>
                  Conecte a matriz com as filiais através do Sincronismo INOVAFARMA. Com ele você pode acompanhar as operações de todas as
                  lojas de qualquer lugar.
                </p>
                <Link to="/sobre/contato">Fale com um especialista</Link>
              </div>
            </div>
            <div className="item-texto">
              <img src={IlustracaoBackup} alt="Inovafarma backup automático de dados para nuvem" className="ilustra" />
              <div className="texto">
                <TituloPrimario>
                  Backup automático de
                  <br /> dados para nuvem
                </TituloPrimario>
                <p>O maior patrimônio da farmácia são as informações do negócio, como cadastros, históricos e movimentações. </p>
                <p>
                  No INOVAFARMA a farmácia evita o risco de perder todas as informações através da ferramenta de backup automático em nuvem.
                </p>
                <Link to="/solucoes/">Saiba como</Link>
              </div>
            </div>
            <div className="item-texto">
              <img src={IlustracaoSegurancaAcesso} alt="Inovafarma segurança de acesso" className="ilustra" />
              <div className="texto">
                <TituloPrimario>Segurança de acesso</TituloPrimario>
                <p>
                  Proteja as informações estratégicas da farmácia e garanta total privacidade dos números de receitas e despesas do acesso
                  de pessoas desautorizadas.
                </p>
                <p>
                  Com o INOVAFARMA o gestor restringe o acesso aos módulos do sistema ou apenas em algumas ferramentas, bloqueando o acesso
                  de colaboradores de acordo com suas atribuições na farmácia.
                </p>
                <Link to="/solucoes/">Saiba como</Link>
              </div>
            </div>
          </Container>
        </SectionInformacoesGerais>

        <FerramentasFacilitadoras>
          <Container>
            <TituloPrimario>Ferramentas integradas que facilitam seu dia a dia</TituloPrimario>
            <TextParagrafo>
              O INOVAFARMA possui diversas ferramentas úteis que podem ser utilizadas para facilitar o fluxo de trabalho na farmácia.
            </TextParagrafo>
            <div className="all-cards">
              <div className="card">
                <div className="img">
                  <img src={IconeSNGPC} alt="SNGPC" />
                </div>
                <h4>SNGPC</h4>
                <p>
                  Facilite o controle dos medicamentos psicotrópicos e antimicrobianos da sua farmácia e mantenha o inventário em
                  conformidade com a Anvisa.
                </p>
              </div>
              <div className="card">
                <div className="img">
                  <img src={IconeAuditoriaEstoque} alt="Auditoria de estoque" />
                </div>
                <h4>Auditoria de estoque</h4>
                <p>
                  Acompanhe a integridade do estoque da sua farmácia e mantenha a consistência do estoque físico com o estoque do sistema.
                </p>
              </div>
              <div className="card">
                <div className="img">
                  <img src={IconeBalancoEstoque} alt="Balanço de estoque" />
                </div>
                <h4>Balanço de estoque</h4>
                <p>
                  Para uma gestão eficaz é necessário registrar o estoque da farmácia dentro do sistema. Facilite esse processo utilizando
                  coletores integrados com o INOVAFARMA.
                </p>
              </div>
              <div className="card">
                <div className="img">
                  <img src={IconeContasPagar} alt="Contas a pagar/receber" />
                </div>
                <h4>Contas a pagar/receber</h4>
                <p>Programe o processo de pagamento e recebimento e mantenha sua organização financeira em dia.</p>
              </div>
              <div className="card">
                <div className="img">
                  <img src={IconeTEF} alt="Integração com TEF" />
                </div>
                <h4>Integração com TEF</h4>
                <p>
                  Garanta mais segurança nas transações financeiras da sua farmácia através do aplicativo TEF integrado ao sistema
                  INOVAFARMA. Melhore a eficiência do seu atendimento.
                </p>
              </div>
              <div className="card">
                <div className="img">
                  <img src={IconePreco} alt="Etiqueta de preço" />
                </div>
                <h4>Etiqueta de preço</h4>
                <p>
                  Crie e personalize as etiquetas da sua farmácia, ou se preferir utilize os modelos já prontos das principais marcas do
                  mercado.
                </p>
              </div>
              <div className="card">
                <div className="img">
                  <img src={IconeGerenciadorEntrega} alt="Gerenciador de entregas" />
                </div>
                <h4>Gerenciador de entregas</h4>
                <p>
                  Organize as vendas para entrega da sua farmácia de forma fácil. Registre as informações de valores, troco e entregador
                  para acompanhamento.
                </p>
              </div>
              <div className="card">
                <div className="img">
                  <img src={IconeCofreGerencial} alt="Cofre gerencial" />
                </div>
                <h4>Cofre gerencial</h4>
                <p>
                  Realize as auditorias dos caixas operacionais e centralize as movimentações financeiras, organizando suas operações de
                  pagamento.
                </p>
              </div>
            </div>
          </Container>
        </FerramentasFacilitadoras>

        <SectionVideo className="bg-gray">
          <Container>
            <TituloPrimario>Veja como é simples fazer a gestão de compra e estoque da sua farmácia</TituloPrimario>
            <Video className="video video-solucoes-complementares" url="https://www.youtube.com/watch?v=xKHkkepRprc" light playing />
          </Container>
        </SectionVideo>

        <Demo />

        <Footer padding_maior={true} />
      </div>
    )
  }
}

export default PageSolucoes
